@use "variables";

@each $percent in variables.$percentage {
  .w-#{$percent} {
    width: percentage($percent / 100) !important;
  }

  .h-#{$percent} {
    height: percentage($percent / 100);
  }

  .h-min-#{$percent} {
    min-height: percentage($percent / 100);
  }
}

@each $content in (max-content, min-content) {
  .w-#{str-slice($content, 0, 3)} {
    width: $content;
  }
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in variables.$spacers {
    $length-x: map-get($lengths, x);
    $length-y: map-get($lengths, y);

    .#{$abbrev}-#{$size} {
      #{$prop}: $length-y $length-x !important;
    }

    .#{$abbrev}t-#{$size} {
      #{$prop}-top: $length-y !important;
    }

    .#{$abbrev}r-#{$size} {
      #{$prop}-right: $length-x !important;
    }

    .#{$abbrev}b-#{$size} {
      #{$prop}-bottom: $length-y !important;
    }

    .#{$abbrev}l-#{$size} {
      #{$prop}-left: $length-x !important;
    }

    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length-y !important;
      #{$prop}-bottom: $length-y !important;
    }

    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length-x !important;
      #{$prop}-right: $length-x !important;
    }
  }
}
