@use "variables";

@each $prop in variables.$display {
  .d-#{$prop} {
    display: $prop;
    @if $prop == flex {
      &.flex-1 {
        flex: 1;
      }
      &.fw-wrap {
        flex-wrap: wrap;
      }
      &.fd-column {
        flex-direction: column;
      }
      &.fd-row {
        flex-direction: row;
      }
      &.ai-start {
        align-items: flex-start;
      }
      &.ai-center {
        align-items: center;
      }
      &.ai-end {
        align-items: flex-end;
      }
      &.as-start {
        align-self: flex-start;
      }
      &.as-center {
        align-self: center;
      }
      &.as-end {
        align-self: flex-end;
      }
      &.jc-start {
        justify-content: flex-start;
      }
      &.jc-center {
        justify-content: center;
      }
      &.jc-end {
        justify-content: flex-end;
      }
      &.jc-between {
        justify-content: space-between;
      }
    }
  }
}
