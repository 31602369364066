@use "variables";

@mixin btnIconAdjustColor($color) {
  span {
    color: $color;
  }
  svg {
    fill: $color;
  }
}

@mixin btnIconBase($justify) {
  display: flex;
  justify-content: $justify;
  align-items: center;
  padding: variables.$spacer * 1 variables.$spacer * 2.5;

  svg,
  span {
    pointer-events: none;
  }
}

.btn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  touch-action: manipulation;
  border: 1px solid variables.$transparent;
  border-radius: 60px;
  transition: all 0.2s;

  font-weight: map-get(variables.$font-weight, 'regular');
  line-height: variables.$font-height * 1.35;
  font-size: map-get(variables.$font-sizes, 'h7');
  padding: variables.$spacer * 1 variables.$spacer * 2.5;

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
    border-color: #564d64;
    background: #564d64;
    color: #333037;
    //background: variables.$gray;
    //border: 1px solid variables.$gray;
    //color: variables.$gray-dark;
    //cursor: not-allowed;
    //@include btnIconAdjustColor(variables.$gray-dark);
  }

  &-green {
    &:not(:disabled).inline {
      background: variables.$green;
      border: 1px solid variables.$green;
      color: variables.$white;
      @include btnIconAdjustColor(variables.$white);
      &:hover,
      &:active {
        //background: variables.$green-dark;
        //border-color: variables.$green-dark;
      }
    }
    &:not(:disabled).outline {
      background: variables.$transparent;
      border: 1px solid variables.$green;
      color: variables.$green;
      @include btnIconAdjustColor(variables.$green);
      &:hover {
        background: variables.$green;
        color: variables.$white;
        @include btnIconAdjustColor(variables.$white);
      }
      &:active {
        //background: variables.$green-dark;
      }
    }
  }

  &-gradient {
    &:not(:disabled).inline {
      background: linear-gradient(92.66deg, #35CE8D 0.44%, #E1D5FA 100%);
      box-shadow: 0 20px 20px rgba(99, 238, 158, 0.4);
      border: none;
      color: variables.$purple;
      @include btnIconAdjustColor(variables.$purple);
      &:hover,
      &:active {
        //background: variables.$green-dark;
        //border-color: variables.$green-dark;
      }
    }
    //&:not(:disabled).outline {
    //  background: variables.$transparent;
    //  border: 1px solid variables.$transparent;
    //  color: variables.$purple;
    //  @include btnIconAdjustColor(variables.$purple);
    //  &:hover {
    //    background: linear-gradient(92.66deg, #35CE8D 0.44%, #E1D5FA 100%);
    //    color: variables.$purple;
    //    @include btnIconAdjustColor(variables.$purple);
    //  }
    //  &:active {
    //    //background: variables.$green-dark;
    //  }
    //}
  }

  &.i-start {
    @include btnIconBase(flex-start);
  }
  &.i-center {
    @include btnIconBase(center);
  }
  &.i-end {
    @include btnIconBase(flex-end);
  }

}
