@use "variables";

.bordered {
  border: 1px solid rgba(variables.$dark, 0.1);
}

@each $prop in (top, left, bottom, right) {
  .bordered-#{$prop} {
    border-#{$prop}: 1px solid rgba(variables.$dark, 0.1);
  }
}

.bordered-transparent {
  border: 1px solid variables.$transparent;
}

@each $class, $px in variables.$border-radius {
  .rounded-#{$class} {
    border-radius: $px;
  }
}
