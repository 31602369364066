// APP

$mobile-point: 1279px;
$mobile-point-xs: 767px;

:root {
  --content-width: 1260px;
  --header-height: 80px;
  --slider-height: 580px;

  @media screen and (max-width: $mobile-point) {
    --content-width: 320px;
    --header-height: 50px;
    --slider-height: 526px;
  }
}

// COLORS

$green: #35CE8D;

$purple: #2A1E3C;
$purple-1: #251935;
$purple-2: #36274B;
$purple-3: #280060;

$white: #FFFFFF;
$black: #000000;
$light: #F5F5F5;
$dark: #363636;

$red: #F5618D;
$pink: #D1BDFA;

$transparent: transparent;

// BORDER

$border-radius: (
  'none': 0px,
  'sm': 4px,
  'md': 8px,
  'lg': 16px,
);

// SPACE

$percentage: (25, 50, 75, 100);

$spacer: 1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;

$spacers: (
  0: (x: 0, y: 0),
  h: (x: ($spacer-x * 0.5), y: ($spacer-y * 0.5)),
  1: (x: ($spacer-x * 1), y: ($spacer-y * 1)),
  2: (x: ($spacer-x * 1.5), y: ($spacer-y * 1.5)),
  3: (x: ($spacer-x * 2), y: ($spacer-y * 2)),
  4: (x: ($spacer-x * 2.5), y: ($spacer-y * 2.5)),
  5: (x: ($spacer-x * 3), y: ($spacer-y * 3))
);

// DISPLAY

$display: none, inline, inline-block, block, flex;

// TYPOGRAPHY

$font-height: 100%;
$font-size: 1rem;
$font-align: (left, center, right);
$font-decoration: (underline, none);
$font-transform: (uppercase, lowercase);

$font-regular: 'Muller Regular', sans-serif;
$font-extra-bold: 'Muller Extra Bold', sans-serif;
$font-bold: 'Muller Bold', sans-serif;

$font-weight: (
  'regular': 400,
  'bold': 600
);

$font-sizes: (
  'h1': $font-size * 2.5,
  'h2': $font-size * 2,
  'h3': $font-size * 1.5,
  'h4': $font-size * 1.25,
  'h5': $font-size * 1.125,
  'h6': $font-size * 1
);
