@use "variables";

$colors: (
  'green': variables.$green,
  'white': variables.$white,
  'light': variables.$light,
  'dark': variables.$dark
);

@each $color, $hex in $colors {
  @each $class, $prop in (bg: background, text: color) {
    .#{$class}-#{$color} {
      #{$prop}: $hex !important;
    }
  }
}
