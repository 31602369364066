@use "assets/scss/uikit/borders";
@use "assets/scss/uikit/buttons";
@use "assets/scss/uikit/colors";
@use "assets/scss/uikit/display";
@use "assets/scss/uikit/inputs";
@use "assets/scss/uikit/normalize";
@use "assets/scss/uikit/spacing";
@use "assets/scss/uikit/typography";
@use "assets/scss/uikit/utilities";
@use "assets/scss/uikit/variables";

@import "assets/fonts/stylesheet.css";
@import "~animate.css";
