@font-face {
    font-family: 'Muller Regular';
    src: url('muller/MullerRegular.eot');
    src: local('Muller Regular'), local('MullerRegular'),
        url('muller/MullerRegular.eot?#iefix') format('embedded-opentype'),
        url('muller/MullerRegular.woff2') format('woff2'),
        url('muller/MullerRegular.woff') format('woff'),
        url('muller/MullerRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Muller Extra Bold';
  src: url('muller/MullerExtraBold.eot');
  src: local('Muller ExtraBold'), local('MullerExtraBold'),
  url('muller/MullerExtraBold.eot?#iefix') format('embedded-opentype'),
  url('muller/MullerExtraBold.woff2') format('woff2'),
  url('muller/MullerExtraBold.woff') format('woff'),
  url('muller/MullerExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Muller Bold';
  src: url('muller/MullerBold.eot');
  src: local('Muller Bold'), local('MullerBold'),
  url('muller/MullerBold.eot?#iefix') format('embedded-opentype'),
  url('muller/MullerBold.woff2') format('woff2'),
  url('muller/MullerBold.woff') format('woff'),
  url('muller/MullerBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
