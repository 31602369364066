@use "variables";

* {
  font-family: variables.$font-regular;
  font-weight: map-get(variables.$font-weight, 'regular');
  color: variables.$dark;
}

@each $class, $size in variables.$font-sizes {
  .#{$class} {
    font-size: $size;
  }

  #{$class} {
    font-size: $size;
  }
}

@each $class, $weight in variables.$font-weight {
  .text-#{$class} {
    font-weight: $weight;
  }
}

@each $align in variables.$font-align {
  .text-#{$align} {
    text-align: $align;
  }
}

@each $decoration in variables.$font-decoration {
  .text-d-#{$decoration} {
    text-decoration: $decoration;
  }
}

@each $transform in variables.$font-transform {
  .text-#{$transform} {
    text-transform: $transform;
  }
}
