.clipped {
  overflow: hidden;
}

.c-pointer {
  cursor: pointer;
}

.transition {
  transition: all 0.2s;
}

.invisible {
  visibility: hidden;
}
