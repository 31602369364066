@use "variables";

@mixin icon($direction) {
  position: relative;

  input {
    width: calc(100% - (#{variables.$spacer * 4} + 2px));

    @if $direction == start {
      padding: variables.$spacer variables.$spacer variables.$spacer variables.$spacer * 3;
    } @else {
      padding: variables.$spacer variables.$spacer * 3 variables.$spacer variables.$spacer;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: absolute;
    top: variables.$spacer * 1.15;
    pointer-events: none;

    @if $direction == start {
      left: variables.$spacer * 0.5;
    } @else {
      right: variables.$spacer * 0.5;
    }
  }
}

.input {
  display: flex;
  flex-direction: column;
  label {
    font-size: map-get(variables.$font-sizes, 'h7');
    line-height: variables.$font-height * 1.35;
  }
  input {
    display: inline-block;
    vertical-align: middle;
    background: variables.$transparent;
    color: variables.$white;
    width: calc(100% - (#{variables.$spacer * 2} + 2px));
    font-size: map-get(variables.$font-sizes, 'h7');
    line-height: variables.$font-height * 1.35;
    padding: variables.$spacer;
    border: 1px solid variables.$pink;
    border-radius: map-get(variables.$border-radius, 'sm');
    appearance: none;
    outline: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transition: all 0.2s;

    &::placeholder {
      color: variables.$pink;
    }
    &:focus {
      border: 1px solid variables.$green !important;
      box-shadow: 0 4px 20px rgba(76, 217, 100, 0.3) !important;
      color: variables.$green !important;
      &::placeholder {
        color: variables.$green !important;
      }
    }
    &:disabled {
      opacity: 0.75;
      cursor: not-allowed;
      border-color: #564d64 !important;
      background: #564d64 !important;
      color: #333037 !important;
    }
  }

  &:not(:disabled).invalid {
    input {
      border-color: variables.$red;
      color: variables.$red;
      &::placeholder {
        color: variables.$red;
      }
    }
  }

  &:not(:disabled).valid {
    input {
      border-color: variables.$green;
      color: variables.$green;
      &::placeholder {
        color: variables.$green;
      }
    }
  }

  &.i-start {
    @include icon(start);
  }
  &.i-end {
    @include icon(end);
  }
}

.textarea {
  display: flex;
  flex-direction: column;
  label {
    font-size: map-get(variables.$font-sizes, 'h7');
    line-height: variables.$font-height * 1.35;
  }
  textarea {
    background: variables.$transparent;
    color: variables.$white;
    width: calc(100% - (#{variables.$spacer * 2} + 2px));
    font-size: map-get(variables.$font-sizes, 'h7');
    line-height: variables.$font-height * 1.35;
    padding: variables.$spacer;
    border: 1px solid variables.$pink;
    border-radius: map-get(variables.$border-radius, 'sm');
    appearance: none;
    outline: none;
    resize: none;
    transition: all 0.2s;

    &::placeholder {
      color: variables.$pink;
    }
    &:focus {
      border: 1px solid variables.$white;
    }
    &:disabled {
      opacity: 0.75;
      cursor: not-allowed;
      border-color: #564d64;
      background: #564d64;
      color: #333037;
      //background: variables.$gray;
      //color: variables.$gray-dark;
    }
  }

  &:not(:disabled).invalid {
    textarea {
      border-color: variables.$red;
      color: variables.$red;
      &::placeholder {
        color: variables.$red;
      }
    }
  }

  &:not(:disabled).valid {
    textarea {
      border-color: variables.$green;
      color: variables.$green;
      &::placeholder {
        color: variables.$green;
      }
    }
  }

}
