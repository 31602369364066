@use "variables";
@import "~normalize.css";

html {
  height: 100%;
  scroll-behavior: smooth;
  body {
    height: 100%;
    main {
      height: 100%;
    }
  }
}

a {
  text-decoration: none;
}

p, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: variables.$spacer;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
